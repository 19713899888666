module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"default":{"colors":{"text":"#fff","background":"#000","primary":"#33e"},"fonts":{"body":"system-ui, sans-serif","heading":"\"Avenir Next\", sans-serif","monospace":"Menlo, monospace"},"cards":{"primary":{"padding":2,"borderRadius":4,"boxShadow":"0 0 8px 8px rgba(0, 0, 0, 0.125)"},"compact":{"padding":1,"borderRadius":2,"border":"1px solid","borderColor":"muted"}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","zh-Hans"],"defaultLanguage":"en-US","i18nextOptions":{"debug":true,"lowerCaseLng":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
